const propertyData = {
  "url": "http://1110ne63rdway2003.com",
  "agent": {
    "name": "Aubrey Martin",
    "phone": "503.443.8889",
    "email": "aubrey@aubreymartin.com"
  },
  "full_address": {"street": "1110 NE 63rd Way, #2003", "city": "Hillsboro", "state":"Oregon", "zipcode": "97124"},
  "display_address": "1110 NE 63rd Way, #2003",
  "property": {
    "listing_price": "$319,888",
    "bedrooms": "2",
    "baths": "2",
    "sq_feet": "1405",
    "lot_size": "N/A",
    "rmls": "20498378",
    "built_in": "2000",
    "blurb": "Charming one-level, ground floor, 2 bed/2 bath open concept home w/ 1 car attached garage, located in Orenco Station! Both bedrooms have walk-in closets, master bath has soaking tub! New: interior paint, lighting, flooring, granite counters, fixtures, tile surround and backsplash, cherry cabinets, and lighting. Fenced patio for entertaining with access from master bed and kitchen, & separate outdoor storage. Convenient to shopping, max and great schools. Community ctr, pool and more!",
    "ammenities": [
    ]
  },
  "image_count": 23,
  "opens": [
    {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
    {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
    {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
  ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2794.9072645688484!2d-122.9174785844403!3d45.53207167910187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950f864940f273%3A0xa5c1641f70c73332!2s1110%20Northeast%2063rd%20Way%20Unit%202003%2C%20Hillsboro%2C%20OR%2097124!5e0!3m2!1sen!2sus!4v1576178516167!5m2!1sen!2sus",
        "youtube":"z488MtRgjXo"
    }
  }

export default propertyData;

